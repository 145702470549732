import { SignupFormScreen } from "./subscreen/SignupFormScreen";
import styles from "./SignupScreen.module.css";
import { classNames } from "@core/lib/classNames";
import { stAnalytics } from "@repo/analytics";
import { captureException } from "@repo/observability";
import { BrowserStorage } from "@repo/storage";
import { type Component, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { useWire } from "@core/wire";

export const SignupScreen: Component<{ invite: { type: "direct" | "link"; token: string } | undefined }> = (props) => {
  const wire = useWire();
  const [state, setState] = createStore({
    email: "",
    status: "idle" as "idle" | "loading" | "sent",
  });

  const onMagicLinkSignUp = async () => {
    setState("status", "loading");

    wire.services.auth.methods
      .signInWithMagicLink(state.email, props.invite)
      .then(() => {
        setState("status", "sent");
      })
      .catch((err) => {
        console.error(err);
        captureException(err);
        stAnalytics.track("error_auth", {
          email: state.email,
          status: state.status,
          error_source: "signup",
          flow: "magic-link",
        });
        setState("status", "idle");
      });

    stAnalytics.track("sign_up_started", {
      flow: "magic-link",
      email: state.email,
    });
  };
  const onGoogleSignUp = async () => {
    try {
      // awaiting so that the redirect doesn't happen before we send out the event
      await stAnalytics.track("sign_up_started", {
        flow: "google",
      });
    } catch (error) {
      captureException(error);
      stAnalytics.track("error_auth", {
        email: state.email,
        error_source: "signup",
        flow: "google",
      });
    }

    /**
     * When signing in with google, we don't have a way to pass the invite token and get it back
     * from the OAUTH flow like with the magic link. Luckily, if the invite token is present in
     * the URL, the OAUTH flow will redirect back to the same url including the invite token.
     *
     * We then intercept that redirect and navigate to the accept invite page.
     * See the mounting logic in apps/webconsole/src/routes/auth.tsx
     */
    wire.services.auth.methods.signInWithGoogle();
  };

  onMount(() => {
    const email = BrowserStorage.getLastUsedMagicLinkEmail();
    if (email) setState("email", email);
  });

  return (
    <div class={classNames("dark", styles["signup"])}>
      <div class={styles["signup__bg"]} />

      <div class={styles["signup__content"]}>
        <SignupFormScreen
          email={state.email}
          status={state.status}
          invite={props.invite}
          setEmail={(email) => setState("email", email)}
          onMagicLink={onMagicLinkSignUp}
          onGoogleSignup={onGoogleSignUp}
        />
      </div>
    </div>
  );
};
