import { useSearchParams } from "@solidjs/router";
import { SignupScreen } from "@core/screens/SignupScreen";

const SignUp = () => {
  const [params] = useSearchParams();
  return (
    <SignupScreen
      invite={
        params.invite
          ? { type: "direct", token: params.invite as string }
          : params.collectionLink
            ? { type: "link", token: params.collectionLink as string }
            : undefined
      }
    />
  );
};

export default SignUp;
